export const WHILE_LIMIT = 40;

export const XsFormat = "ddd";

export const defaultFormat = 'D, ddd';

export const monthFormat = "MMM D";

export const XsMontFormat = "MMM";

export const mockSlotTime = {
    pickUpMin: "07:00",
    pickUpMax: "09:00",
    dropOffMin: "16:00",
    dropOffMax: "19:00",
}